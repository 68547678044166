import { h } from '@app/utils';
import { User, Themes, Objectives } from '@app/api';
import { createAndSaveCSRFToken, addCSRFToken, isDefined } from '@app/core';

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                const user = props.initial

                let createUser = User.createUserMutual(
                    user.email,
                    user.password,
                    'klesia_netflix'
                );

                // create user
                createUser.then(async ({ data }) => {
                    if (isDefined(data) && !isDefined(data.expiration)) {

                        return false;
                    } else {
                        localStorage.removeItem('password');
                        await localStorage.setItem('apitoken', data.token);
                        await localStorage.setItem('refresh', data.refresh);
                        await localStorage.setItem('expiration', data.expiration.date);
                    }
                    // send cgu
                    User.updateUser({
                        'cgu': '1'
                    }).then(async () => {
                        let browserLanguage = 'fr_FR';
                        if (isDefined(localStorage.getItem('language'))) {
                            browserLanguage = localStorage.getItem('language');
                        }
                        if (browserLanguage.indexOf('_') === -1) {
                            browserLanguage = 'fr_FR';
                        }
                        // send initial profile
                        User.updateUser({
                            'gender': user.gender,
                            'nickname': user.fname,
                            'language': browserLanguage,
                        }).then(async () => {
                            let toSend = {};
                            user.objectives.map((m, i) => toSend[i] = {'id': m.id, 'state': 1});

                            Objectives.updateObjective(toSend, false).then(() => {
                                //
                            }).then(() => {
                                // send the payment
                                actions.goToOnboarding();
                            })
                        })
                    })
                })
            },

            goToOnboarding: () => (state, actions) => {
                createAndSaveCSRFToken();
                window.main.location.go(addCSRFToken('/onboarding'));
            },

            setState: ({ ns, key, value }) => (state, actions) => ({
                [ns]: { ...state[ns], ...{ [key]: value } }
            }),
        }
        return action;
    }
}
