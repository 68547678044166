import { t, h } from '@app/utils';
import { allRoutes } from '@app/settings';
import { Avatar, Balloon, Button, Form, Tile, MessagePopup } from '@app/elements';

import logoStep from '@app/img/register/custo/klesia_netflix/your-cgu.png';
import logoStepx2 from '@app/img/register/custo/klesia_netflix/your-cgu@2x.png';

import './../index.scss';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                {state.messageActive &&
                    <MessagePopup kind={'error'} active={'active'} duration={10000} durationCallback={() => actions.displayErrorMessage(false)} message={t('Vous n‘avez pas de token utilisateur. Vous devez passer par le site', {ns: 'register'}) + ' ' + state.customer + ' ' + t('pour vous inscrire!', {ns: 'register'})}></MessagePopup>
                }

                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8' style={{ 'height': '130px' }}>
                        <div class='col-4 btzScrollWrapper p-0'>
                            <p class='btzTitle btzXl'>{t('Conditions générales', {ns: 'register'})}<br />{t('d’utilisation', {ns: 'register'})}</p>
                            <p class='btzTexte'>{t('Nous prenons envers vous des engagements sur la sécurisation de vos données.', {ns: 'register'})}</p>
                            <embed src={allRoutes['pdf.cgu'].pathname} type='application/pdf'>
                                <p>This browser does not support PDFs. Please download the PDF to view it: <a href={allRoutes['pdf.cgu'].pathname}>Download PDF</a>.</p>
                            </embed>
                        </div>
                    </Tile.Content>
                </Tile>

                {state.alfred.isVisible && (
                    <Tile classes={'btzAlfred centered'}>
                        <Tile.Icon>
                            <Avatar.Alfred kind={'error'} classes={'centered'} />
                        </Tile.Icon>
                        <Tile.Content>
                            <Balloon kind={'error'}>
                                {t('Veuillez accepter les CGU pour passer à la suite.', {ns: 'register'})}
                            </Balloon>
                        </Tile.Content>
                    </Tile>
                )}

                <Form onsubmit={event => event.preventDefault()} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <div style={{ 'display': 'table', 'width': '500px', 'height': '100%', 'margin': '0 auto' }}>
                            <Button primary onclick={() => {
                                actions.onRefusedTerms()
                                actions.updateParentProps(props)
                            }}>
                                {t('Refuser', {ns: 'generals'})}
                            </Button>
                            <Button primary active={state.cguInitialized} onclick={(event) => {
                                if (state.cguInitialized) {
                                    actions.onAcceptedTerms();
                                    actions.updateParentProps(props);
                                    actions.onStepSubmit({event: event, props: props});
                                }
                            }}>
                                {t('Accepter', {ns: 'generals'})}
                            </Button>
                        </div>
                    </Form.Group>

                </Form>

            </div>
        );
        return view;
    }
}
