import { t, h } from '@app/utils';
import { Button, Form, Tile } from '@app/elements';

import logoStep from '@app/img/register/custo/klesia_netflix/your-data.png';
import logoStepx2 from '@app/img/register/custo/klesia_netflix/your-data@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                        <p class='btzTitle btzXl'>{t('Bienvenue sur Klesia', {ns: 'register'})}</p>
                        <p class='btzTexte'>{t('Le service qui vous aide à prendre soin de votre santé, sympa non ?', {ns: 'register'})}</p>
                        <p class='btzTexte'>{t('Commencez la configuration.', {ns: 'register'})}</p>
                    </Tile.Content>
                </Tile>

                <Form onsubmit={event => event.preventDefault()} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary active onclick={() => {
                            actions.updateParentProps(props);
                            actions.onStepSubmit(props);
                        }}>
                            {t('Commencer', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        );
        return view;
    }
}