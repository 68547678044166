import { t, h } from '@app/utils';
import { Avatar, Balloon, Button, Form, Tile } from '@app/elements';

import logoStep from '@app/img/register/custo/klesia_netflix/your-ids.png';
import logoStepx2 from '@app/img/register/custo/klesia_netflix/your-ids@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                        <p class='btzTitle btzXl'>{t('Vos identifiants', {ns: 'register'})}</p>
                        <p class='btzTexte'>{t('Enfin, votre compte est sécurisé par la combinaison de votre email et le mot de passe de votre choix (8 caractères minimum).', {ns: 'register'})}</p>
                        <Form onsubmit={event => event.preventDefault()}>

                            <Form.Group>
                                <Form.Input
                                    classes={'btzFullWidth'}
                                    placeholder={t('adresse@mail.com', {ns: 'register'})}
                                    type='email'
                                    id='email'
                                    name='email'
                                    value={state.email.value}
                                    oninput={(event) => actions.onEmailInputDebouncedUpdate(event.target.value)}
                                    onfocus={() => actions.onEmailInputTouched()}
                                    classes={(state.email.isTouched) ? (state.email.isValid ? 'is-success' : 'is-error') : ''}
                                    readonly={state.isGuest}
                                    required
                                />
                                <Form.Input
                                    classes={'btzFullWidth'}
                                    placeholder={t('Votre mot de passe', {ns: 'register'})}
                                    type='password'
                                    id='password'
                                    name='password'
                                    value={state.password.value}
                                    oninput={(event) => actions.onPasswordInputDebouncedUpdate(event.target.value)}
                                    onfocus={() => actions.onPasswordInputTouched()}
                                    toggle={() => actions.onPasswordClickUpdate()}
                                    classes={(state.password.isTouched) ? (state.password.isValid ? 'is-success' : 'is-error') : ''}
                                    required
                                />
                            </Form.Group>

                            <Form.Group classes='btzForm-btzFooter'>
                                <Button primary block onclick={(event) => {
                                    actions.updateParentProps(props)
                                    actions.onStepSubmit(event)
                                }}
                                loading={(state.formIsSubmitting)}
                                active={(state.email.isValid && state.password.isValid)}>
                                    {t('Valider', {ns: 'generals'})}
                                </Button>
                            </Form.Group>
                        </Form>

                        {state.api.message != '' && (
                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                <Tile.Icon>
                                    <Avatar.Alfred kind={state.api.kind} classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={state.api.kind} style={{ 'white-space': 'normal' }} actionAfterError={() => {actions.setApiErrors({'message': '','kind': 'info'});}}>
                                        {state.api.message}
                                    </Balloon>
                                </Tile.Content>
                            </Tile>
                        )}

                    </Tile.Content>
                </Tile>
            </div>
        );
        return view;
    }
}