import { t, h } from '@app/utils';
import { Button, Card, TextTransform } from '@app/elements';
import AccountLayout from '@app/layouts/page/splitted/trackers/layout';

import Tracker from './../tracker';

import icoBack from '@app/img/ico/ico-prev-light.png';
import icoBackx2 from '@app/img/ico/ico-prev-light@2x.png';

import icoClose from '@app/img/ico/ico-close-black.png';
import icoClosex2 from '@app/img/ico/ico-close-black@2x.png';

import bgNoTrackersx2 from '@app/img/trackers/bg-no-trackers@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <AccountLayout key={state.uniqId} page='oc' readablePageLabel={t('Votre compte', {ns: 'account'})} readablePageDescription={t('Pour gérer au mieux votre accompagnement santé', {ns: 'account'})} backbutton={(state.currentDisplayedTrackersList.length > 0) ? true : false} backaction={actions.resetCurrentTrackers} userMedia={state.userMedia} customer={props.customer}>
                {(state.currentDisplayedTrackersList.length === 0) &&
                    <div key={'oc-user-listing'}>
                        {((state.connected.length > 0) && (state.conflictStep === '')) &&
                            <Card id={'oc-connected'} key={'oc-connected'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'oc'} style={{ 'min-height': '100%' }}>
                                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                    <div class='col-12'>
                                        <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Vos objets connectés', {ns: 'oc'})}</p>
                                        <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Retrouvez ci-dessous les objets connectés à votre compte.', {ns: 'oc'})}</p>
                                    </div>
                                </Card.Header>

                                <Card.Body>
                                    <div>
                                        {state.connected.map(item =>
                                            <div class='col-12 p-0'>
                                                <div key={item.id}><Tracker key={'oc-connected-' + item.id} {...item} connectTracker={actions.deleteThisTracker} kind={'connected'}></Tracker></div>
                                            </div>
                                        )}
                                    </div>
                                </Card.Body>

                                <Card.Footer></Card.Footer>
                            </Card>
                        }
                    </div>
                }

                {(state.conflictObjFirst !== null) &&
                    <Card classes={'btzPageCard'} kind={'oc'}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <div class='col-12 p-0'>
                                {state.conflictStep == 'tracker' &&
                                    <div>
                                        <div class='col-6 col-md-12 p-0' style={{ 'vertical-align': 'top' }}>
                                            <p class='col-12 btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Conflit entre deux objets', {ns: 'oc'})}</p>
                                            <p class='col-12 btzTimeline-btzCard-btzTitle btzPageContentTitle btzPageContentTitleBlue'>{t('SYNCHRONISATION', {ns: 'oc'})}</p>
                                            <p class='col-12 btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle btzPageContentSubTitleConflict'>
                                                {t('Vous avez connecté deux objets qui importent des données similaires.', {ns: 'oc'})}<br/><br/>
                                                {t('Lequel voulez-vous synchroniser ?', {ns: 'oc'})}
                                            </p>
                                        </div>

                                        <div class='col-6 col-md-12 p-0'>
                                            <div key={state.conflictObjFirst.id} class='col-6 col-xs-12'><Tracker {...state.conflictObjFirst} selectTracker={actions.selectSelectedConlictedObj} kind={'conflict'} active={(state.selectedConflictObj == null ? '' : ((state.conflictObjFirst.name == state.selectedConflictObj.name) ? 'btzActive' : ''))}></Tracker></div>
                                            <div key={state.conflictObjSecond.id} class='col-6 col-xs-12'><Tracker {...state.conflictObjSecond} selectTracker={actions.selectSelectedConlictedObj} kind={'conflict'} active={(state.selectedConflictObj == null ? '' : ((state.conflictObjSecond.name == state.selectedConflictObj.name) ? 'btzActive' : ''))}></Tracker></div>
                                        </div>

                                        <div>
                                            <div style={{ 'display': 'inline-block' }}>
                                                <Button primary cancel onclick={() => {
                                                    actions.cancelTrackerConnection(props)
                                                }}>
                                                    {t('Annuler', {ns: 'oc'})}
                                                </Button>
                                            </div>

                                            <div style={{ 'display': 'inline-block' }}>
                                                <Button primary active={state.selectedConflictObj !== null} onclick={() => {
                                                    if (state.selectedConflictObj !== null) {
                                                        actions.confirmTrackerSelection(props);
                                                    }
                                                }}>
                                                    {t('Enregistrer', {ns: 'oc'})}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {state.conflictStep == 'historique' &&
                                    <div>
                                        <div class='btzOc-btzBackFromHistoriqueConflict' onclick={() => {
                                            actions.setConflictStep('tracker')
                                        }}>
                                            <img src={icoBack} srcset={`${icoBackx2} 2x`} alt='' />
                                        </div>

                                        <div>
                                            <div class='col-6 col-md-12 p-0' style={{ 'vertical-align': 'top' }}>
                                                <p class='col-12 btzTimeline-btzCard-btzTitle btzPageContentTitle'>Conflit entre deux objets</p>
                                                <p class='col-12 btzTimeline-btzCard-btzTitle btzPageContentTitle btzPageContentTitleBlue'>HISTORIQUE</p>
                                                <p class='col-12 btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle' style={{ 'padding-top': '25px' }}>
                                                    {t('Vous avez choisi de sychroniser un nouvel objet,', {ns: 'oc'})} {state.selectedConflictObj.brand.toUpperCase()} - {state.selectedConflictObj.name}.<br/><br/>
                                                    {t('Quel historique voulez-vous conserver ?', {ns: 'oc'})}
                                                </p>
                                            </div>

                                            <div class='col-6 col-md-12 p-0'>
                                                <div key={state.conflictObjFirst.id} class='col-6 col-xs-12'><Tracker {...state.conflictObjFirst} selectTracker={actions.setSelectedConflictedHistorique} kind={'conflict'} active={(state.selectedConflictHistorique == null ? '' : ((state.conflictObjFirst.name == state.selectedConflictHistorique.name) ? 'btzActive' : ''))}></Tracker></div>
                                                <div key={state.conflictObjSecond.id} class='col-6 col-xs-12'><Tracker {...state.conflictObjSecond} selectTracker={actions.setSelectedConflictedHistorique} kind={'conflict'} active={(state.selectedConflictHistorique == null ? '' : ((state.conflictObjSecond.name == state.selectedConflictHistorique.name) ? 'btzActive' : ''))}></Tracker></div>
                                            </div>

                                            {state.selectedConflictObj !== null &&
                                                <div>
                                                    <div style={{ 'display': 'inline-block' }}>
                                                        <Button primary onclick={() => {
                                                            actions.cancelTrackerConnection(props)
                                                        }}>
                                                            {t('Annuler', {ns: 'oc'})}
                                                        </Button>
                                                    </div>

                                                    <div style={{ 'display': 'inline-block' }}>
                                                        <Button primary active onclick={() => {
                                                            actions.sendConflicts(props)
                                                        }}>
                                                            {t('Enregistrer', {ns: 'oc'})}
                                                        </Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </Card.Header>
                    </Card>
                }

                {(((state.brands.length > 0) && (state.currentDisplayedTrackersList.length === 0)) || (state.currentDisplayedTrackersList.length > 0)) ?
                    <Card classes={'btzPageCard'} kind={'oc'}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            {((state.brands.length > 0) && (state.currentDisplayedTrackersList.length === 0)) &&
                                <div class='col-12' style={{ 'padding': '0 35px' }}>
                                    <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Objets compatibles', {ns: 'oc'})}</p>
                                    <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Sélectionnez la marque de votre appareil pour le connecter à votre compte.', {ns: 'oc'})}</p>
                                </div>
                            }

                            {(state.currentDisplayedTrackersList.length > 0) &&
                                <div class='col-12'>
                                    <div class='col-8 p-0' style={{ 'vertical-align': 'middle' }}>
                                        <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'><TextTransform mode={'capitalize'}>{state.currentDisplayedTrackersList[0].brand}</TextTransform></p>
                                        <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Retrouvez ci-dessous les objets connectés de la marque', {ns: 'oc'})} <TextTransform mode={'capitalize'}>{state.currentDisplayedTrackersList[0].brand}</TextTransform>.</p>
                                    </div>
                                    <div class='col-4 p-0' style={{ 'vertical-align': 'middle', 'text-align': 'right' }}>
                                        <img src={process.env.PUBLIC_URL + '/img/trackers/brand/logo-' + state.currentDisplayedTrackersList[0].brand + '.png'} srcset={process.env.PUBLIC_URL + '/img/trackers/brand/logo-' + state.currentDisplayedTrackersList[0].brand + '@2x.png 2x'} alt='' />
                                    </div>
                                </div>
                            }
                        </Card.Header>

                        <Card.Body style={{ 'margin-bottom': '-15px' }}>
                            {((state.brands.length > 0) && (state.currentDisplayedTrackersList.length === 0)) &&
                                <div style={{ 'text-align': 'left' }}>
                                    {state.brands.map(item =>
                                        <div class='col-3 col-md-6 col-xs-12'>
                                            <div key={item.id}><Tracker {...item} exclude={state.exclude} selectTrackerList={actions.setCurrentTrackers} kind={'brand'}></Tracker></div>
                                        </div>
                                    )}
                                </div>
                            }

                            {(state.currentDisplayedTrackersList.length > 0) &&
                                <div style={{ 'text-align': 'left' }}>
                                    {state.currentDisplayedTrackersList.filter((item) => item.state === 0).map(item =>
                                        <div class='col-6 col-xs-12'>
                                            <div key={item.id}><Tracker {...item} exclude={state.exclude} connectTracker={actions.connectThisTracker} kind={'tracker'}></Tracker></div>
                                        </div>
                                    )}
                                </div>
                            }
                        </Card.Body>

                        <Card.Footer></Card.Footer>
                    </Card>
                    :
                    <Card classes={'btzPageCard'} kind={'oc'}>
                        <Card.Body style={{ 'margin-top': '0px' }}>
                            <p class='' style='margin: 0px;padding: 0px;'>{t('Téléchargez ou connectez vous sur votre application mobile pour profiter de cette fonctionnalité')}</p>
                         </Card.Body>
                    </Card>
                }
            </AccountLayout>
        );
        return view;
    }
}
