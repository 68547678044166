import { t, h } from '@app/utils';
import { isDefined } from '@app/core';
import './../index.scss';

import * as staticsconstants from '@app/constants/constants-statics';
import TimelineLayout from '@app/layouts/timeline/layout';

import Advice from './../cards/advice';
import Article from './../cards/article';
import Recipe from './../cards/recipe';
import Quizz from './../cards/quizz';
import Citation from './../cards/citation';
import NoTimeline from './../cards/notimeline';

import UAlfred from './../user/alfred';
import UChallenge from './../user/challenge';
import UNotifications from './../user/notifications';
import UProfil from './../user/profil';
import UPrograms from './../user/programs';

import imgLoader from '@app/img/loader.gif';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div id='btzTimelineRoot' class='btzTimelineRoot' key={state.uniqId}>
                <TimelineLayout page='timeline' key={'timeline-' + state.uniqId} userMedia={(((state.user.profil !== null) && isDefined(state.user.profil.media)) ? state.user.profil.media.url : null)} customer={props.customer} language={props.language}>
                    <div class='col-12'>
                        <div class='col-3 col-md-12 col-sides btzTimeline-btzTimelineContainers'>
                            <div class='btzTimeline-btzTimelineLeft'>
                                {((state.user.profil !== null) && isDefined(state.user.profil.media)) &&
                                    <div key='uprofil'><UProfil nick={state.user.profil.nick} gender={state.user.profil.gender} url={state.user.profil.media.url} default={state.user.profil.media.default} seniority={state.user.profil.profile.seniority} customer={props.customer}></UProfil></div>
                                }
                                {(state.user.alfred !== null) &&
                                    <div key='ualfred'><UAlfred {...state.user.alfred}></UAlfred></div>
                                }
                            </div>
                        </div>

                        <div class='col-6 col-md-12 col-center btzTimeline-btzTimelineContainers' style={{ 'padding-top': '67px' }}>
                            <div class='btzTimeline-btzTimelineCenter'>
                                {state.cards.advices === null ?
                                    <div class='btzLoadingContainer-btzWrapper' style={{ 'margin-bottom': '25px' }}>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                    :
                                    <div>
                                        {state.cards.advices.map(item =>
                                            <div key={item.id}><Advice {...item} displayedon='timeline'></Advice></div>
                                        )}
                                    </div>
                                }

                                {state.cards.articles === null ?
                                    <div class='btzLoadingContainer-btzWrapper' style={{ 'margin-bottom': '25px' }}>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                    :
                                    <div>
                                        {state.cards.articles.map(item =>
                                            <div key={item.id}><Article {...item} displayedon='timeline'></Article></div>
                                        )}
                                    </div>
                                }

                                {state.cards.recipes === null ?
                                    <div class='btzLoadingContainer-btzWrapper' style={{ 'margin-bottom': '25px' }}>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                    :
                                    <div>
                                        {state.cards.recipes.map(item =>
                                            <div key={item.id}><Recipe {...item} displayedon='timeline'></Recipe></div>
                                        )}
                                    </div>
                                }

                                {state.cards.citations === null ?
                                    <div class='btzLoadingContainer-btzWrapper' style={{ 'margin-bottom': '25px' }}>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                    :
                                    <div>
                                        {state.cards.citations.map(item =>
                                            <div key={item.id}><Citation {...item} displayedon='timeline'></Citation></div>
                                        )}
                                    </div>
                                }

                                {state.cards.quizzs === null ?
                                    <div class='btzLoadingContainer-btzWrapper' style={{ 'margin-bottom': '25px' }}>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                    :
                                    <div>
                                        {state.cards.quizzs.map(item =>
                                            <div key={item.id}><Quizz {...item} displayedon='timeline' language={props.language}></Quizz></div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>

                        <div class='col-3 col-md-12 col-sides btzTimeline-btzTimelineContainers' style={{ 'padding-top': '67px' }}>
                            {state.mainTimelineLoaded === true ?
                                <div class='btzTimeline-btzTimelineRight'>
                                    {((staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.programs === true) && (staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.programsSeparateNotification === true)) &&
                                        <div class='btzTimeline-btzTimelineRight-btzNotificationsWrapper'>
                                            <div key={'programs-' + state.user.id}><UPrograms {...state.user} customer={props.customer}></UPrograms></div>
                                        </div>
                                    }
                                    {((state.user.profil !== null) && (state.displayedNotifications === true)) &&
                                        <div class='btzTimeline-btzTimelineRight-btzNotificationsWrapper'>
                                            <div key={'meteo-' + state.user.id}><UNotifications {...state.user} customer={props.customer}></UNotifications></div>
                                        </div>
                                    }
                                    {state.user.challenges.running.length > 0 &&
                                        <div key={state.user.challenges.running[0].id}><UChallenge {...state.user.challenges.running[0]} propagateEndedChallenge={actions.removeEndedChallenge}></UChallenge></div>
                                    }
                                    {((state.user.challenges.running.length === 0) && (state.user.challenges.recommanded !== null)) &&
                                        <div key={state.user.challenges.recommanded.length}><UChallenge {...state.user.challenges.recommanded[0]} propagateEndedChallenge={actions.removeRecommandedEndedChallenge} recommandedRefused={actions.recommandedRefused}></UChallenge></div>
                                    }
                                </div>
                                :
                                <div class='btzLoadingContainer-btzWrapper' style={{ 'margin-bottom': '25px', 'height': '500px' }}>
                                    <div class='btzLoadingContainer'></div>
                                </div>
                            }
                        </div>
                    </div>

                    <div class='col-12 btzPreviousTimeline'>
                        {state.timelines.map(timeline =>
                            <div>
                                {timeline.cards !== undefined &&
                                    <div>
                                        {timeline.cards.length === 0 &&
                                            <div>
                                                <div class='col-3 col-md-12 col-sides btzTimeline-btzDayContainer'>
                                                </div>
                                                <div key='notimeline' class='col-6 col-md-12 col-center btzTimeline-btzTimelineContainers'>
                                                    <p class='btzTitle btzXl' style={{ 'text-transform': 'capitalize' }}>{timeline.date}</p>
                                                    <NoTimeline></NoTimeline>
                                                </div>
                                                <div class='col-3 col-md-12 col-sides btzTimeline-btzTimelineContainers'></div>
                                            </div>
                                        }

                                        {timeline.cards.length > 0 &&
                                            <div>
                                                <div class='col-3 col-md-12 col-sides btzTimeline-btzDayContainer'>
                                                </div>

                                                <div class='col-6 col-md-12 col-center btzTimeline-btzTimelineContainers'>
                                                    <p class='btzTitle btzXl' style={{ 'text-transform': 'capitalize' }}>{timeline.date}</p>
                                                    <div class='btzTimeline-btzTimelineCenter btzTimeline-btzPreviousTimelineCenter'>
                                                        {timeline.cards.map(item =>
                                                            <div key={item.id}>
                                                                {item.type == 2 &&
                                                                    <Advice {...item} propagate={actions.propagateFavoriteStatus} update={actions.rendering} displayedon='timeline'></Advice>
                                                                }
                                                                {item.type == 12 &&
                                                                    <Quizz {...item} displayedon='timeline' language={props.language}></Quizz>
                                                                }
                                                                {item.type == 18 &&
                                                                    <Article {...item} propagate={actions.propagateFavoriteStatus} update={actions.rendering} displayedon='timeline'></Article>
                                                                }
                                                                {item.type == 19 &&
                                                                    <Recipe {...item} propagate={actions.propagateFavoriteStatus} update={actions.rendering} displayedon='timeline'></Recipe>
                                                                }
                                                                {item.type == 20 &&
                                                                    <Citation {...item} propagate={actions.propagateFavoriteStatus} update={actions.rendering} displayedon='timeline'></Citation>
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div class='col-3 col-md-12 col-sides btzTimeline-btzTimelineContainers'></div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        )}
                    </div>

                    {state.isLoading &&
                        <img style={{ 'width': '50px', 'height': '50px' }} src={imgLoader} alt='' class='' />
                    }

                    {state.isRegisterDay &&
                        <p class='btzTitle btzXl'>{'• ' + t('Jour de votre inscription', {ns: 'timeline'}) + ' •'}</p>
                    }
                </TimelineLayout>
            </div>
        );
        return view;
    }
}
