import { t, h } from '@app/utils';
import { Avatar, Balloon, Form, Tile } from '@app/elements';

import logoStepMan from '@app/img/register/custo/klesia_netflix/areyou-man.png';
import logoStepManx2 from '@app/img/register/custo/klesia_netflix/areyou-man@2x.png';

import logoStepWoman from '@app/img/register/custo/klesia_netflix/areyou-woman.png';
import logoStepWomanx2 from '@app/img/register/custo/klesia_netflix/areyou-woman@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <Tile.Content>
                        <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', height: '115px' }}>
                            {t('Êtes-vous un homme ou', {ns: 'register'})}<br />{t('une femme ?', {ns: 'register'})}
                        </p>
                    </Tile.Content>
                </Tile>

                <Form onsubmit={event => event.preventDefault()}>

                    <Form.Group style={{ 'white-space': 'nowrap', 'text-align': 'center' }}>
                        <Form.Radio
                            placeholder={t('Homme', {ns: 'register'})}
                            id='genderM'
                            name='gender'
                            image={logoStepMan}
                            imagex2={logoStepManx2}
                            onclick={(event) => {
                                actions.onManClick()

                                props.send({
                                    ns: 'user',
                                    key: 'gender',
                                    value: 1
                                })

                                actions.onStepSubmit({ev: event, props: props})
                            }}
                            required
                            checked={state.sex.value === 1}
                        />
                        <Form.Radio
                            placeholder={t('Femme', {ns: 'register'})}
                            id='genderF'
                            name='gender'
                            image={logoStepWoman}
                            imagex2={logoStepWomanx2}
                            onclick={(event) => {
                                actions.onWomanClick()

                                props.send({
                                    ns: 'user',
                                    key: 'gender',
                                    value: 0
                                })

                                actions.onStepSubmit({ev: event, props: props})
                            }}
                            required
                            checked={state.sex.value === 0}
                        />

                        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                            <Tile.Icon>
                                <Avatar.Alfred kind={'info'} classes={'centered'} />
                            </Tile.Icon>
                            <Tile.Content>
                                <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                    <p style={{ 'text-align': 'left' }}>
                                        <span class='btzBaloon-btzTitle'>{t('INSCRIPTION', {ns: 'register'})}</span>
                                        <span> - 2/4</span>
                                        <font>
                                            <br />{t('Sélectionnez l‘un de deux boutons.', {ns: 'register'})}
                                        </font>
                                    </p>
                                </Balloon>
                            </Tile.Content>
                        </Tile>
                    </Form.Group>
                </Form>
            </div>
        );
        return view;
    }
}
