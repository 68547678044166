import arrowImgLeft from './klesia_netflix/arrow-left.png';
import arrowImgLeftX2 from './klesia_netflix/arrow-left@2x.png';

import arrowImgRight from './klesia_netflix/arrow-right.png';
import arrowImgRightX2 from './klesia_netflix/arrow-right@2x.png';

export const ARROWS = {
    'arrow_left' : {
        'normal': arrowImgLeft,
        'retina': arrowImgLeftX2
    },
    'arrow_right' : {
        'normal': arrowImgRight,
        'retina': arrowImgRightX2
    },
};