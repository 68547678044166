/*
IMPORTANT : #1 - if translations are needed, put translation key in [constants-default.js],
            loading order make error if its setted here

 */
export const PUBLIC_ROUTES = ['/home', '/public', 'medical-study', '/reset/password/', '/session/reset', '/payment', '/account/oc',
    '/satisfaction', '/mail', '/transit/webtoapp', 'waiting', '/unsubscribeChoice',
    '/mobileapp/', '/device/acknowledge', '/device/authorize', '/email-confirm',
    '/apple', '/android', '/ihealth', '/misfit', '/runkeeper', '/garmin', '/polar', '/fitbit', '/withings', '/adminpreview',
    '/application/', '/sport/', '/biologie/', '/landing/'
];

export const CUSTOMERS = {
    'betterise': {
        'title': 'Betterise',
        'description': 'Betterise Health Tech',
        'i18ndescription': 'Betterise description',
        'mailto': 'alfred@betterise.me',
        'appurl': {
            'ios': 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            'android': 'https://play.google.com/store/apps/details?id=com.betterise.android',
            'iosbanner': 'app-id=916264011',
            'androidbanner': 'app-id=com.betterise.android',
        },
        'appredirectFromPublic': false,
        'pdf': {
            'cgu': 'pdf.cgu',
            'cga': 'pdf.cga'
        },
        'helper': 'Alfred',
        'name': 'Betterise',
        'readablecustomer': 'Betterise',
        'reserved': 'Betterise Health Tech',
        'redirect': {
            'sharedFrom': 'Betterise',
            'gotoLabel': 'Betterise',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Betterise.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': true,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': false,
            'V6toV7transit': true,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'partena': {
        'title': 'Partena',
        'description': 'Partena description',
        'i18ndescription': 'Partena description',
        'mailto': 'zoe@partenamutvitalite.be',
        'appurl': {
            'ios': 'https://itunes.apple.com/fr/app/partenamut-vitalit%C3%A9/id1424943436?ls=1&mt=8',
            'android': 'https://play.google.com/store/apps/details?id=com.partena.android&hl=fr&ah=RTf41zOT7B3u5kMLV1bY_1M1p8E',
            'iosbanner': 'app-id=1424943436',
            'androidbanner': 'app-id=com.partena.android',
        },
        'appredirectFromPublic': false,
        'pdf': {
            'cgu': 'pdf.partena.cgu',
        },
        'helper': 'Alfred',
        'name': 'Partena',
        'readablecustomer': 'Betterise',
        'reserved': 'Partenamut',
        'redirect': {
            'sharedFrom': 'Partenamut Vitalité',
            'gotoLabel': 'Partenamut',
            'gotoUrl': 'http://www.partenamut.be/vitalite?utm_medium=email&utm_source=app_vitalite&utm_campaign=visit_partenamut-be'
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Partena.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': true,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': true,
            'V6toV7transit': true,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'gjensidige': {
        'title': 'Iver',
        'description': 'Gjensidige description',
        'i18ndescription': 'Gjensidige description',
        'mailto': 'iver@gjensidige.no',
        'appurl': {
            'ios': 'https://itunes.apple.com/no/app/iver/id1298542367?mt=8',
            'android': 'https://play.google.com/store/apps/details?id=com.betterise.gjensidige&hl=fr',
            'iosbanner': 'app-id=1298542367',
            'androidbanner': 'app-id=com.betterise.gjensidige',
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.iver.cgu',
            'cga': 'pdf.cga',
        },
        'helper': 'Iver',
        'name': 'Iver',
        'readablecustomer': 'Iver',
        'redirect': {
            'sharedFrom': 'Iver',
            'gotoLabel': 'Iver',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Gjensidige.',
        'idleLogout': false,
        'forcedLanguage': 'no_NO',
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': true,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': true,
            'V6toV7transit': true,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'cerba': {
        'title': 'Eliott',
        'description': 'Cerba description',
        'i18ndescription': 'Cerba description',
        'mailto': 'contact@elio.tt',
        'appurl': {
            'ios': 'https://itunes.apple.com/app/id1455475309',
            'android': 'https://play.google.com/store/apps/details?id=com.cerba.eliott',
            'iosbanner': 'app-id=1455475309',
            'androidbanner': 'app-id=com.cerba.eliott',
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.cerba.cgu',
        },
        'helper': 'Eliott',
        'name': 'Eliott',
        'readablecustomer': 'Eliott',
        'reserved': 'Cerballiance',
        'redirect': {
            'sharedFrom': 'Eliott',
            'gotoLabel': 'Eliott',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Eliott.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [1, 22, 58],
        'customOnboardingEnd': true,
        'modules': {
            'landing': true,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': false,
            'V6toV7transit': false,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': true,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': false,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': false,
            'desableThemes': [],
            'noSWRoutes': ['/landing', '/sport', '/biologie', '/application'],
            'blanklayout': false,
            'onboardingskip': false,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'feelgood': {
        'title': 'I Feel Good',
        'description': 'I Feel Good description',
        'i18ndescription': 'I Feel Good description',
        'mailto': 'emma@sgsante.fr',
        'appurl': {
            'ios': 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            'android': 'https://play.google.com/store/apps/details?id=com.betterise.android',
            'iosbanner': 'app-id=916264011',
            'androidbanner': 'app-id=com.betterise.android',
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.cgu',
            'cga': 'pdf.cga'
        },
        'helper': 'Emma',
        'logout': `${process.env.APP_API_URL}/ageo/logout`,
        'resiliate': 'no-pwd',
        'name': 'I Feel Good',
        'readablecustomer': 'I Feel Good',
        'reserved': 'I Feel Good',
        'redirect': {
            'sharedFrom': 'I Feel Good',
            'gotoLabel': 'I Feel Good',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': true,
            'url': `${process.env.APP_API_URL}/ageo/`,
            'withAccessToken': true,
            'withLoginToken': true,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Feelgood.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': false,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': true,
            'V6toV7transit': true,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': false,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': false,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': false,
                'sexAfterPseudo': false,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'vyv': {
        'title': 'B.E by Vivoptim',
        'description': 'B.E by Vivoptim description',
        'i18ndescription': 'B.E by Vivoptim description',
        'mailto': 'alfred@byvivoptim.com',
        'appurl': {
            'ios': 'https://itunes.apple.com/us/app/be-by-vivoptim/id1445770237?mt=8',
            'android': 'https://play.google.com/store/apps/details?id=com.bebyvivoptim.android',
            'iosbanner': 'app-id=1445770237',
            'androidbanner': 'app-id=com.bebyvivoptim.android',
        },
        'appredirectFromPublic': false,
        'pdf': {
            'cgu': 'pdf.vyv.cgu',
        },
        'helper': 'Alfred',
        'name': 'B.E by Vivoptim',
        'readablecustomer': 'Vyv',
        'reserved': 'Vivoptim Solutions',
        'redirect': {
            'sharedFrom': 'B.E by Vivoptim',
            'gotoLabel': 'B.E by Vivoptim',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': ['mgel', 'smeba', 'harmonie', 'vyv'],
            'register': {
                'selected': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
                'ignored': null,
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par B.E.',
        'idleLogout': true,
        'forcedLanguage': 'fr_FR',
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': false,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': false,
            'V6toV7transit': true,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': 'mutualhealth',
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'maternite': {
        'title': 'MBM',
        'description': 'MBM description',
        'i18ndescription': 'MBM description',
        'mailto': 'sophie@moibientotmaman.com',
        'appurl': {
            'ios': 'https://itunes.apple.com/fr/app/moi-bient%C3%B4t-maman/id1074304962?mt=8',
            'android': 'https://play.google.com/store/apps/details?id=com.betterise.mbm',
            'iosbanner': 'app-id=1074304962',
            'androidbanner': 'app-id=com.betterise.mbm',
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.cgu',
        },
        'helper': 'Sophie',
        'name': 'Moi Bientôt Maman',
        'redirect': {
            'sharedFrom': 'l’application « Moi, Bientôt Maman »',
            'gotoLabel': 'l’application « Moi, Bientôt Maman »',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Moi, Bientôt Maman.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': true,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': true,
            'V6toV7transit': true,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'coronavirus': {
        'title': 'CoachCov',
        'description': 'Betterise Health Tech',
        'i18ndescription': 'Betterise description',
        'mailto': 'alfred@betterise.me',
        'appurl': {
            'ios': '',
            'android': '',
            'iosbanner': null,
            'androidbanner': null,
        },
        'appredirectFromPublic': false,
        'pdf': {
            'cgu': 'pdf.coronavirus.cgu',
            'cga': 'pdf.cga'
        },
        'helper': 'Alfred',
        'name': 'CoachCov',
        'readablecustomer': 'CoachCov',
        'reserved': 'Betterise Health Tech',
        'redirect': {
            'sharedFrom': 'CoachCov',
            'gotoLabel': 'CoachCov',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par CoachCov.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': true,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': false,
            'V6toV7transit': false,
            'timeline': true,
            'nutrition': false,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': false,
                'oc': false,
                'payment': false,
                'abonnement': true,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': false,
                'payment': true,
                'device': true,
                'whopay': false,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': false,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'theraflow': {
        'title': 'Theraflow',
        'description': 'Betterise Health Tech',
        'i18ndescription': 'Betterise description',
        'mailto': 'alfred@betterise.me',
        'appurl': {
            'ios': '',
            'android': '',
            'iosbanner': null,
            'androidbanner': null,
        },
        'appredirectFromPublic': false,
        'pdf': {
            'cgu': 'pdf.cgu',
            'cga': 'pdf.cga'
        },
        'helper': 'Alfred',
        'name': 'Theraflow',
        'readablecustomer': 'Theraflow',
        'reserved': 'Betterise Health Tech',
        'redirect': {
            'sharedFrom': 'Theraflow',
            'gotoLabel': 'Theraflow',
            'gotoUrl': ''
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Theraflow.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': false,
            'login': false,
            'medicalLogin': true,
            'medicalLoginURL': 'home.login.medical',
            'register': false,
            'appredirect': false,
            'V6toV7transit': false,
            'timeline': false,
            'nutrition': false,
            'dashboard': false,
            'programs': false,
            'programsSeparateNotification': false,
            'account': false,
            'follow': false,
            'disclamer': false,
            'accountMod': {
                'parameters': false,
                'objectives': false,
                'favorites': false,
                'badges': false,
                'oc': false,
                'payment': false,
                'abonnement': false,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': false,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': true,
            'onboardingskip': false,
            'mobileOnboarding': true,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'klesia': {
        'title': 'Klesia',
        'description': 'Klesia',
        'i18ndescription': 'Klesia description',
        'mailto': 'elisa@carcept-prev.fr',
        'appurl': {
            'ios': '',
            'android': '',
            'iosbanner': null,
            'androidbanner': null,
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.cgu',
            'cga': 'pdf.cga'
        },
        'customerRedirect': {
            'redirect': true,
            'url': `${process.env.APP_API_URL}/klesia/`,
            'withAccessToken': false,
            'withLoginToken': true,
        },
        'helper': 'Elisa',
        'logout': `${process.env.APP_API_URL}/klesia/logout`,
        'name': 'TVB',
        'readablecustomer': 'Klesia',
        'reserved': 'Betterise Health Tech',
        'redirect': {
            'sharedFrom': 'Transportez-vous bien',
            'gotoLabel': 'TVB',
            'gotoUrl': `${process.env.APP_API_URL}/klesia/`,
            'forced': `${process.env.APP_API_URL}/klesia/`,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Klesia.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': false,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': true,
            'V6toV7transit': true,
            'timeline': true,
            'nutrition': true,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': false,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': true,
                    'denivele': true,
                    'sleep': true,
                },
                'payment': true,
                'abonnement': true,
            },
            'accountEdition': {
                'password': false,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': false,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': false,
                'sexAfterPseudo': false,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'klesia_netflix': {
        'title': 'Klesia',
        'description': 'Klesia',
        'i18ndescription': 'Klesia description',
        'mailto': 'julie@klesia.fr',
        'appurl': {
            'ios': '',
            'android': '',
            'iosbanner': null,
            'androidbanner': null,
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.cgu',
            'cga': 'pdf.cga'
        },
        'helper': 'Julie',
        'logout': 'https://www.klesia.fr/espace-client-particulier',
        'name': 'Klesia',
        'readablecustomer': 'Klesia',
        'reserved': 'Betterise Health Tech',
        'redirect': {
            'sharedFrom': 'Klesia',
            'gotoLabel': 'Klesia',
            'gotoUrl': 'https://www.klesia.fr/espace-client-particulier',
            'forced': 'https://www.klesia.fr/espace-client-particulier',
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': true,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Klesia Netflix.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': false,
            'login': true,
            'medicalLogin': false,
            'medicalLoginURL': '',
            'register': true,
            'appredirect': true,
            'V6toV7transit': false,
            'timeline': true,
            'nutrition': false,
            'dashboard': true,
            'programs': true,
            'programsSeparateNotification': true,
            'account': true,
            'follow': false,
            'disclamer': true,
            'accountMod': {
                'parameters': true,
                'objectives': true,
                'favorites': true,
                'badges': true,
                'oc': true,
                'ocSpecific': {
                    'activity': true,
                    'sport': false,
                    'denivele': false,
                    'sleep': false,
                },
                'payment': false,
                'abonnement': false,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': false,
                'payment': false,
                'device': false,
                'whopay': false,
                'directpay': null,
                'cguAfterDevice': false,
                'sexAfterPseudo': true,
            },
            'gamifications': true,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': false,
            'onboardingskip': true,
            'mobileOnboarding': false,
            'meteo': false,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Mon journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Mon alimentation',
                'title': 'Mon alimentation'
            },
            'dashboard': {
                'menu': 'Mes données',
                'title': 'Mes données'
            },
            'programs': {
                'menu': 'Mes programmes',
                'title': 'Mes programmes de coaching',
                'running': 'Mes programmes en cours',
                'recommanded': 'Les programmes recommandés pour mes objectifs'
            },
            'account': {
                'menu': 'Mon compte',
                'title': 'Mon compte'
            },
            'parameters': {
                'menu': 'Mon compte',
                'title': 'Mes informations'
            },
            'favorites': {
                'menu': 'Mes favoris',
                'title': 'Mes favoris'
            },
            'rewards': {
                'menu': 'Mes badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Mes objets connectés',
                'title': 'Mes objets connectés'
            },
            'objectives': {
                'menu': 'Mes objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'oncoflow': {
        'title': 'Oncolaxy',
        'description': 'Oncolaxy',
        'i18ndescription': 'Oncolaxy description',
        'mailto': 'elisa@carcept-prev.fr',
        'appurl': {
            'ios': '',
            'android': '',
            'iosbanner': null,
            'androidbanner': null,
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.cgu',
            'cga': 'pdf.cga'
        },
        'helper': 'Elisa',
        'name': 'Oncolaxy',
        'readablecustomer': 'Oncolaxy',
        'reserved': 'Betterise Health Tech',
        'redirect': {
            'sharedFrom': 'Oncolaxy',
            'gotoLabel': 'Oncolaxy',
            'gotoUrl': '',
            'forced': '',
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Oncolaxy.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': false,
            'login': false,
            'medicalLogin': true,
            'medicalLoginURL': 'home.login.medical',
            'register': false,
            'appredirect': false,
            'V6toV7transit': false,
            'timeline': false,
            'nutrition': false,
            'dashboard': false,
            'programs': false,
            'programsSeparateNotification': false,
            'account': false,
            'follow': false,
            'disclamer': false,
            'accountMod': {
                'parameters': false,
                'objectives': false,
                'favorites': false,
                'badges': false,
                'oc': false,
                'payment': false,
                'abonnement': false,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': false,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': true,
            'onboardingskip': false,
            'mobileOnboarding': true,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
    'cardiolaxy': {
        'title': 'Cardiolaxy',
        'description': 'Cardiolaxy',
        'i18ndescription': 'Cardiolaxy description',
        'mailto': 'elisa@carcept-prev.fr',
        'appurl': {
            'ios': '',
            'android': '',
            'iosbanner': null,
            'androidbanner': null,
        },
        'appredirectFromPublic': true,
        'pdf': {
            'cgu': 'pdf.cgu',
            'cga': 'pdf.cga'
        },
        'helper': 'Elisa',
        'name': 'Cardiolaxy',
        'readablecustomer': 'Cardiolaxy',
        'reserved': 'Betterise Health Tech',
        'redirect': {
            'sharedFrom': 'Cardiolaxy',
            'gotoLabel': 'Cardiolaxy',
            'gotoUrl': '',
            'forced': '',
        },
        'customerRedirect': {
            'redirect': false,
            'url': null,
            'withAccessToken': false,
            'withLoginToken': false,
        },
        'particularMutuals': {
            'login': [],
            'register': {
                'selected': null,
                'ignored': ['mgel', 'smeba', 'harmonie mutuelle', 'vyv'],
            }
        },
        'wrongPlateFormMessage': 'Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Cardiolaxy.',
        'idleLogout': false,
        'forcedLanguage': null,
        'customOnboardingCards': [],
        'customOnboardingEnd': false,
        'modules': {
            'landing': false,
            'login': false,
            'medicalLogin': true,
            'medicalLoginURL': 'home.login.medical',
            'register': false,
            'appredirect': false,
            'V6toV7transit': false,
            'timeline': false,
            'nutrition': false,
            'dashboard': false,
            'programs': false,
            'programsSeparateNotification': false,
            'account': false,
            'follow': false,
            'disclamer': false,
            'accountMod': {
                'parameters': false,
                'objectives': false,
                'favorites': false,
                'badges': false,
                'oc': false,
                'payment': false,
                'abonnement': false,
            },
            'accountEdition': {
                'password': true,
                'email': true,
                'gender': true,
                'nick': true,
                'city': true,
                'birthdate': true,
                'language': true,
                'timezone': true,
                'metrics': true
            },
            'registerWorkflow': {
                'secure': true,
                'payment': true,
                'device': true,
                'whopay': true,
                'directpay': null,
                'cguAfterDevice': true,
                'sexAfterPseudo': true,
            },
            'gamifications': false,
            'desableThemes': [],
            'noSWRoutes': [],
            'blanklayout': true,
            'onboardingskip': false,
            'mobileOnboarding': true,
            'meteo': true,
        },
        'menuNames': {
            'timeline': {
                'menu': 'Journal',
                'title': ''
            },
            'nutrition': {
                'menu': 'Alimentation',
                'title': 'Votre alimentation'
            },
            'dashboard': {
                'menu': 'Données',
                'title': 'Vos données'
            },
            'programs': {
                'menu': 'Programmes',
                'title': 'Vos programmes de coaching',
                'running': 'Vos programmes en cours',
                'recommanded': 'Les programmes recommandés pour vos objectifs'
            },
            'account': {
                'menu': 'Compte',
                'title': 'Votre compte'
            },
            'parameters': {
                'menu': 'Paramètres',
                'title': 'Vos informations'
            },
            'favorites': {
                'menu': 'Favoris',
                'title': 'Vos favoris'
            },
            'rewards': {
                'menu': 'Badges',
                'title': 'Tous les badges'
            },
            'oc': {
                'menu': 'Objets connectés',
                'title': 'Vos objets connectés'
            },
            'objectives': {
                'menu': 'Objectifs',
                'title': 'Objectif en cours'
            },
        }
    },
};

export const CUSTOMERS_COMPANY_PROVISIONNING = ['cerba', 'auchan'];
export const CUSTOMERS_WHYTE_BRAND = ['cerba', 'feelgood'];

export const ERRORS = {
    'deactivated': {'kind': 'error', 'message': 'Ce compte est désactivé.', 'key': ['103', '210'], 'time': '2500'},
    'expired': {'kind': 'error', 'message': 'Votre session a expiré.', 'key': ['204', '205'], 'time': '2500'},
};